<template>
  <router-view/>
</template>

<script>

export default {
  data () {
    return {
    }
  },
  computed: {
    details () {
      return this.$store.state.details
    }
    // ...mapGetters(['getSideImage'])
  },
  created () {
    this.$store.dispatch('getDetails')
    this.$store.dispatch('getQuestions')
    this.$store.dispatch('getJobs')
    // this.$store.commit('setImg', this.details.intro_image)
  }
}
</script>

<style lang="scss">

body {
  margin: 0;
  font-family: 'Titillium Web', sans-serif;
  font-weight: 300;
  color: #000;
  font-size: 16px;
}

.btn {
  padding: 5px 25px;
  border-radius: 35px;
  border: 3px solid #000;
  background-color: transparent;
  color: #000;
  text-decoration: none;
  font-size: 30px;
  font-weight: 600;
  transition: all 0.25s;
  cursor: pointer;

   @media all and (max-width: 540px) {
     font-size: 18px;
     padding: 5px 25px;
   }

  &:hover {
    background-color: #000;
    color: #fff;
  }
}

main:not(.about),
main:not(.home) {
  display: grid;
  grid-template-columns:50vw 50vw;
  //width: 100vw;
  min-height: 100vh;

  @media all and (max-width: 768px) {
    display: block;
  }
}
main.about,
main.home {
  width: 100vw;
  min-height: 100vh;
  display: grid;
  grid-template-columns:100vw;
}

.mainContent {
    text-align: right;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-end;
    padding-right: 70px;
    padding-left: 70px;
    padding-bottom: 70px;

    @media all and (max-width: 540px) {
      padding-right: 20px;
      padding-left: 20px;
      padding-bottom: 20px;
    }

    h1, h2, h3, h4 {
      margin: 0;
      line-height: 1.1;
      font-family: 'Fredoka One', cursive;
      font-weight: normal;
    }

    h1 {
      font-size: 70px;
      font-weight: normal;
      max-width: 900px;
      line-height: 1.1;
      font-family: 'Fredoka One', cursive;
    }
  }

  .simplebar-content .content,
  .simplebar-content ul,
  .simplebar-content ol {
    padding-right: 30px;
  }

  .simplebar-track.simplebar-vertical {
    //right: -20px;
    width: 8px;
    border-right: 1px solid #000;
    top: 0;
    bottom: 0;

    .simplebar-scrollbar {
      border-radius: 0;
      top: 0;
      bottom: 0;
      right: 0;

      &:before {
        top: 0 !important;
        bottom: 0 !important;
        right: 0;
        border-radius: 0;
        opacity: 1;
        left: 8px;background-color: #000;
      }
    }
  }

  .mobile-img {
    position: relative;
    overflow: hidden;
    height: 200px;
    width: 100%;

    @media all and (min-width: 540px) {
      height: 300px;
      margin-top: 50px;
      margin-bottom: 50px;
    }

    @media all and (min-width: 769px) {
      display: none;
    }

    img {
      min-width: 100%;
      height: 100%;
      display: block;
      object-fit: cover;
      object-position: center center;
      /* height: 100%;
      width: auto;
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%,-50%); */
    }
  }

  .fade-enter-active,
  .fade-leave-active {
    transition: all .1s;
  }
  .fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
    opacity: 0;
  }
</style>
